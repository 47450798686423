import React, {Component} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import GetAppIcon from '@mui/icons-material/GetApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import FaxIcon from '@mui/icons-material/Fax';
import TollIcon from '@mui/icons-material/Toll';


function InvestorHelpDesk() {
  
  return (
    <>    <Header/>
    <div className="contentblock">
        <div className="container">
               <div className="row">
                   
                     <div className="col-lg-12 col-md-12">
                            <div className="section__title-wrapper">
                              <h2 className="section__title font-36">Investor Help Desk</h2>
                           </div>
                        <div className="tableblock">

                              <div className="row">
                                
                              <div className="address-heading">
                                  <h5>ADDRESS OF THE REGISTRAR &amp; SHARE TRANSFER AGENT</h5>
                                      
                                  <div className="col-lg-12 col-md-12 adblocks">
          
                                      <p className="web-name">Skyline Financial Services Private Limited</p>
                                    <p> <ImportContactsIcon/> Address : D-153 A, 1 st Floor, Okhla Industrial Area, Phase - I ,
    New Delhi –110 020</p>
                                    <p><PhoneIcon/> Tel: +91-11-26812682</p>
                                    <p><MailIcon/> Email:  admin@skylinerta.com</p>
                                    <p><LanguageIcon/> Website : www.skylinerta.com</p>
                                    
                                  </div>
                              </div>
                                
                                <div className="address-heading">
                                    <h5>REGISTERED OFFICE</h5>
                                        
                                    <div className="col-lg-12 col-md-12 adblocks">
            
                                        <p className="web-name">Kamdhenu Ventures Limited</p>
                                        <p><LineWeightIcon/> CIN : U51909HR2019PLC089207</p>
                                      <p> <ImportContactsIcon/> Address : 2nd Floor, Tower A, Building No.9,
DLF Cyber City, Phase-III,
Gurugram, Haryana-122002</p>
                                      <p><PhoneIcon/> phone : 0124-4604500</p>
                                      <p><MailIcon/> Email: cs@kamdhenupaints.com</p>
                                      <p><LanguageIcon/> Website : www.kamdhenupaints.com</p>
                                      
                                    </div>
                                </div>
                                
                                <div className="address-heading">
                                    <h5>COMPANY SECRETARY AND COMPLIANCE OFFICER</h5>
                                        
                                    <div className="col-lg-12 col-md-12 adblocks">
            
                                      <p> <ImportContactsIcon/> Address : 2nd Floor, Tower A, Building No.9,
DLF Cyber City, Phase-III,
Gurugram, Haryana-122002</p>
                                      <p><PhoneIcon/> phone : 0124-4604500</p>
                                      <p><MailIcon/> Email: cs@kamdhenupaints.com</p>
                                      
                                    </div>
                                </div>

                                <div className="address-heading">
                                    <h5>NODAL OFFICER FOR THE IEPF AUTHORITY</h5>
                                        
                                    <div className="col-lg-12 col-md-12 adblocks">
            
                                      <p> <ImportContactsIcon/> Address : 2nd Floor, Tower A, Building No.9,
DLF Cyber City, Phase-III,
Gurugram, Haryana-122002</p>
                                      <p><PhoneIcon/> phone : 0124-4604500</p>
                                      <p><MailIcon/> Email: cs@kamdhenupaints.com</p>
                                      
                                    </div>
                                </div>

                                <div className="address-heading">
                                    <h5>LIST OF KEY MANAGERIAL PERSONNEL</h5>
                                        
                                    <div className="col-lg-12 col-md-12 adblocks">
                                   
                                        <table cellPadding={0} cellSpacing={0} width={100} className="contact-table">
                                            <tr>
                                                <td>Names of KMPs</td>
                                                <td>Shri Saurabh Agarwal, Managing Director<br/>
Shri Vineet Kumar Agarwal, Chief Financial Officer
</td>
                                            </tr>
                                            <tr>
                                                <td>Contact Details</td>
                                                <td><div className="col-lg-12 col-md-12 adblocks">
            
            <p className="">Kamdhenu Ventures Limited</p>
          
          <p> <ImportContactsIcon/> Address : 2nd Floor, Tower A, Building No.9,
DLF Cyber City, Phase-III,
Gurugram, Haryana-122002</p>
          <p><PhoneIcon/> phone : 0124-4604500</p>
          <p><MailIcon/> Email: cs@kamdhenupaints.com</p>
          <p><LanguageIcon/> Website : www.kamdhenupaints.com</p>
          
        </div></td>
                                            </tr>
                                            </table>
                                    </div>
                                </div>






         
                              </div>
                        </div>
                     </div>     
                </div>                
        </div>
    </div>

<div><Footer /></div>
    </>

  );
}

export default InvestorHelpDesk;
